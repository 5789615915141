<template>
  <ion-row v-if="visibleTypes.length" class="ion-padding-bottom">
    <slot />
    <ion-card v-if="showTypeCard" class="ion-padding-bottom data-legend-card">
      <ion-grid>
        <ion-row class="header">
          <ion-col>
            <ion-badge v-if="!timeless">{{ hover.time || '--:--' }}</ion-badge>
          </ion-col>
        </ion-row>
        <ion-row v-for="(type, index) in visibleTypes" :key="index">
          <ion-col>
            <img :src="type.icon" v-if="type.icon" class="icon" width="15" />
            <span class="label" v-html="type.label" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
    <DataCard v-for="(source,index) in dataSources"
              :source="source"
              :key="index"
              :types="visibleTypes"
              :values="hover[source.uuid || source.serialNumber]"
              :mainUuid="settings.group.uuid"
              :time="hover.time"
              :showTypes="!showTypeCard && index === 0" />
  </ion-row>
  <ion-row v-else>
    <ion-card class="ion-padding-bottom data-legend-card">
      <ion-grid>
        <ion-row class="header">
          <ion-col>
            <ion-badge>{{ '--:--' }}</ion-badge>
          </ion-col>
        </ion-row>
        <template v-if="loading">
          <ion-row v-for="k in 3"
                   :key="k">
            <ion-col>
              <skeleton />
            </ion-col>
          </ion-row>
        </template>
      </ion-grid>
    </ion-card>
  </ion-row>
</template>

<script>
import DataCard from '~gro-components/DataCard'
import { SensorTypeHelper, CLIMATE_ZONE } from '~gro-modules/Sensor'

export default {
  props: {
    hover: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    timeless: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      required: true,
    },
    combineFirst: {
      type: Boolean,
      default: false,
    },
  },
  components: { DataCard },
  computed: {
    visibleTypes () {
      return this.settings.sensorTypes
        .filter(type => type.available && type.enabled)
        .map(type => {
          return {
            ...type,
            label: SensorTypeHelper.$t(type.name, 'label'),
            icon: SensorTypeHelper.getSetting(type.name, 'svg', null),
            order: (type.zone === CLIMATE_ZONE ? 10 : 0) + SensorTypeHelper.getSetting(type.name, 'zoneListPosition', 0),
          }
        })
        .sort((typeA, typeB) => typeA.order - typeB.order)
    },
    showTypeCard () {
      return !this.combineFirst || this.dataSources.length > 1
    },
    dataSources () {
      let sources = []
      if (this.settings.comparedGroups) {
        sources = [...this.settings.comparedGroups]
      }
      if (this.settings.comparedDevices) {
        sources = [...sources, ...this.settings.comparedDevices]
      }
      if (this.settings.group && (this.settings.group.visible || sources.length === 0)) {
        sources.unshift(this.settings.group)
      }
      return sources
    },
  },
}
</script>
<style lang="scss" scoped>
.data-legend-card {
  margin: 2px;
  min-width: 164px;

  .header {
    min-height: 46px;
  }

  ion-row {
    min-height: 20px;
  }

  .label {
    font-size: 12px;
    color: var(--ion-color-dark);
    vertical-align: middle;
    margin-right: 5px;
  }

  .icon {
    vertical-align: middle;
    margin-right: 4px;
  }
}
</style>
