<template>
  <ion-list class="seamless">
    <ion-item v-for="(option,index) in options"
              :key="index"
              lines='full'
              button
              :disabled="option.disabled"
              @click="option.handler">
      {{ option.text }}
       <ion-icon v-if="option.icon" :name="option.icon"  slot="start"/>
    </ion-item>
  </ion-list>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
}
</script>
