<template>
  <ion-grid class="full-height" v-if="loading">
    <ion-row class="full-height ion-align-items-center ion-justify-content-center ion-text-center">
      <gro-spinner />
    </ion-row>
  </ion-grid>
  <div ref="highchart"
       :class='{ "ion-hide" : loading, "dense" : dense }'
       class="chart-container"
  />
</template>

<script>
import HighCharts from 'highcharts/highstock'
import exportData from 'highcharts/modules/export-data'
import noData from 'highcharts/modules/no-data-to-display'

export default {
  components: {},
  data () {
    return {
      chart: null,
    }
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    axis: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Object,
      default: null,
    },
  },
  watch: {
    loading (value) {
      if (value) return this.chart.showLoading()
      this.chart.hideLoading()
      this.reflow()
    },
    axis: {
      handler: 'updateAxis',
    },
    series: {
      handler: 'updateSeries',
    },
    highlight: {
      handler: 'updateHighlight',
    },
  },
  methods: {
    reflow () {
      if (!this.chart) return
      this.$nextTick(() => {
        this.chart.reflow()
      })
    },
    setExtremes (min, max) {
      if (!this.chart || !this.chart.xAxis.length) return
      this.chart.xAxis[0].setExtremes(min, max)
    },
    setHeight (height) {
      if (!this.chart) return
      this.chart.setSize(null, height)
      this.reflow()
    },
    updateAxis (newAxises, force = true) {
      newAxises.forEach(newAxis => {
        const axis = this.chart.yAxis.find(axis => {
          return axis.userOptions.id === newAxis.id
        })
        if (axis) return axis.update(newAxis, false)
        this.chart.addAxis(newAxis, false, false)
      })
      if (force) {
        const axisToRemove = []
        this.chart.yAxis.forEach(axis => {
          if (
            !axis.userOptions.id || (
              !newAxises.find(a => a.id === axis.userOptions.id) &&
              axis.userOptions.id.substr(0, 9) !== 'navigator'
            )
          ) {
            axisToRemove.push(axis)
          }
        })

        axisToRemove.forEach(axis => axis.remove(false))
      }
      this.chart.redraw()
      if (this.series && this.chart.series && this.series.length > this.chart.series.length) {
        this.$nextTick(this.updateSeries)
      }
    },
    updateSeries () {
      this.series.forEach(newSerie => {
        const serie = this.chart.series.find(s => s.name === newSerie.name)
        if (serie) {
          serie.remove(false)
          this.chart.addSeries(newSerie, false)
          return
        }
        if (this.chart.yAxis.find(axis => axis.userOptions.id === newSerie.yAxis)) {
          this.chart.addSeries(newSerie, false)
        } else {
          console.log(`No axis for series ${newSerie.yAxis}`)
        }
      })
      const seriesToRemove = []
      this.chart.series.forEach(serie => {
        if (!this.series.find(s => s.name === serie.name) &&
          serie.userOptions.id !== 'navigator') {
          seriesToRemove.push(serie)
        }
      })
      seriesToRemove.forEach(serie => serie.remove(false))
      if (this.highlight) {
        this.updateHighlight()
      } else {
        this.chart.redraw()
      }
      this.reflow()
    },
    updateOptions (options) {
      this.chart.update(options)
    },
    updateVisibility (type, visible = false, updateAxis = true) {
      this.chart.series.filter(serie => {
        return serie.userOptions.sensorType === type.name &&
          serie.visible !== visible &&
          serie.userOptions.id !== 'navigator'
      }).forEach(serie => {
        serie.setVisible(visible, false)
        if (!updateAxis) return
        if (visible) {
          serie.yAxis.update({ visible }, true)
        } else {
          serie.yAxis.update({ visible }, false)
        }
      })
    },
    updateHighlight () {
      this.chart.series.forEach(serie => {
        if (this.highlight?.type === 'group' && serie.userOptions.deviceGroup?.uuid === this.highlight.uuid) {
          serie.update({
            opacity: 1,
          }, false)
        } else if (
          this.highlight?.type === 'device' &&
          serie.userOptions.device?.serialNumber === this.highlight.serialNumber
        ) {
          serie.update({
            opacity: 1,
          }, false)
        } else {
          serie.update({
            opacity: 0.5,
          }, false)
        }
      })
      this.chart.redraw()
    },
    downloadCsv (fileName = 'export') {
      const csv = this.chart.getCSV()
      if (!csv) throw Error('Unable to export, no data found')
      const downloadLink = document.createElement('a')
      downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      downloadLink.target = '_blank'
      downloadLink.download = `${fileName}.csv`
      downloadLink.click()
    },
  },
  mounted () {
    noData(HighCharts)
    exportData(HighCharts)
    this.chart = HighCharts.chart(this.$refs.highchart, this.options)
    if (this.loading) this.chart.showLoading()
    window.chart = this.chart
  },
}
</script>

<style lang="scss">
.chart-container {
  position: relative;
  height: 100%;

  &.dense {
    height: 300px;
  }
}
</style>
