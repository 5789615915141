<template>
  <ion-grid class="full-height">
    <component :is="steps[current]" />
    <navigation-footer @primaryClick="next"
                       @backClick="previous"
                       :primaryLabel="buttonLabel" />
  </ion-grid>
</template>

<script>
export default {
  emits: ['done'],
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      current: 0,
    }
  },
  computed: {
    buttonLabel () {
      if (this.lastStep) return this.$t('common.Done')
      return this.$t('common.Next')
    },
    firstStep () {
      return this.current === 0
    },
    lastStep () {
      return this.current === this.steps.length - 1
    },
  },
  methods: {
    next () {
      if (this.lastStep) {
        this.$emit('done')
      } else {
        this.current++
      }
    },
    previous () {
      if (this.firstStep) {
        this.$goBack()
      } else {
        this.current--
      }
    },
  },
}
</script>
