<template>
  <ion-card class="ion-padding-bottom data-card">
    <ion-grid>
      <ion-row v-if="isDevice" class="header ion-padding-bottom">
        <ion-col v-if="showTypes">
          <ion-badge>{{ time || '--:--' }}</ion-badge>
        </ion-col>
        <ion-col>
          <div class="title">{{ source.serialNumber }}</div>
          <div v-if="typeGroup" class="device-count">{{ $t(`device.typeGroups.${typeGroup}`) }}
            <span v-if="!source.group">({{ $t(`device.Unassigned`) }})</span>
            <span v-else-if="source.group.uuid !== mainUuid">({{ source.group.name }})</span>
          </div>
        </ion-col>
      </ion-row>
      <ion-row v-else class="header ion-padding-bottom">
        <ion-col v-if="showTypes">
          <ion-badge>{{ time || '--:--' }}</ion-badge>
        </ion-col>
        <ion-col>
          <div class="title">{{ $t('dashboard.Crop average') }}</div>
          <div class="device-count">{{ source.devices?.length }} {{ $t('device.Plural') }}</div>
        </ion-col>
      </ion-row>
      <ion-row v-for="(type, index) in typeValues" :key="index">
        <ion-col v-if="showTypes" class="type-icon-row">
          <img :src="type.icon" class="icon-egro icon" v-if="type.icon" />
          <span v-html="type.label" class="label" />
        </ion-col>
        <ion-col>
          <SensorValue no-label colorized :type="type.name" :value="type.value" />
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</template>

<script>
import SensorValue from '~gro-components/SensorValue'
import { deviceSensorTypeGroup } from '~gro-modules/Device'
import { AvailableTypesHelper } from '~gro-modules/DeviceGroup'
import { SensorTypeHelper } from '~gro-modules/Sensor'
import { computed } from 'vue'

export default {
  components: { SensorValue },
  props: {
    source: {
      type: Object,
      required: true,
    },
    showTypes: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Array,
      required: true,
    },
    values: {
      type: Object,
    },
    mainUuid: {
      type: String,
      required: true,
    },
    time: {
      type: String,
    },
  },
  setup (props) {
    const label = computed(() => SensorTypeHelper.$t(props.type, 'label'))
    const isDevice = computed(() => !!props.source.serialNumber)
    const typeGroup = computed(() => {
      if (!isDevice.value) return ''
      return deviceSensorTypeGroup(props.source.type)
    })
    const backupValues = computed(() => {
      return AvailableTypesHelper.load(props.source.serialNumber ?? props.mainUuid)
    })
    const typeValues = computed(() => {
      return props.types.map(type => {
        return {
          ...type,
          value: props.values && typeof props.values[type.name] === 'number'
            ? props.values[type.name]
            : backupValues.value[type.name],
        }
      })
    })
    return {
      label,
      isDevice,
      typeGroup,
      typeValues,
    }
  },
}
</script>
<style lang="scss" scoped>
.data-card {
  margin: 2px;
  width: 124px;

  .header {
    min-height: 46px;
  }

  ion-row {
    min-height: 20px;
  }

  .sensor-value {
    padding-left: 0px;
  }

  .title {
    font-size: 12px;
    font-weight: 700;
  }

  .device-count {
    font-size: 10px;
    color: var(--ion-color-medium)
  }
}

.type-icon-row {
  .label {
    font-size: 12px;
    color: var(--ion-color-dark);
    vertical-align: middle;
    @media only screen and (max-width: 360px) {
      display: none
    }
  }

  .icon {
    vertical-align: middle;
    margin-right: 4px;
  }

  margin: auto;
}
</style>
